import { PrivilegesService } from './services/privilegesService'
import { APP_BASE_HREF, registerLocaleData } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n'
import zh from '@angular/common/locales/zh'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ApiService } from './services/api.service'
import { TokenInterceptor } from './services/token-interceptor'
import { GuardService } from './services/guard.service'
import { MomentModule } from 'angular2-moment'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { EditorModule } from '@tinymce/tinymce-angular'
import { LoginComponent } from './modules/login/login.component'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { PreviewComponent } from './modules/preview/preview.component'
import { VirtualScrollerModule } from 'ngx-virtual-scroller'
import { NzCarouselModule } from 'ng-zorro-antd/carousel'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzMessageModule } from 'ng-zorro-antd/message'
import * as Sentry from '@sentry/angular'
import { Router } from '@angular/router'
import { TOKEN_PROVIDER } from '@maimemo/memo-admin-components'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { environment } from '../environments/environment'

registerLocaleData(zh)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PreviewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    MatTooltipModule,
    MatDialogModule,
    CKEditorModule,
    EditorModule,
    FormsModule,
    ScrollingModule,
    LazyLoadImageModule,
    VirtualScrollerModule,
    NzCarouselModule,
    NzLayoutModule,
    NzButtonModule,
    NzDatePickerModule,
    NzFormModule,
    NzInputModule,
    NzModalModule,
    NzNotificationModule,
    NzToolTipModule,
    NzIconModule,
    NzMessageModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ApiService,
    GuardService,
    PrivilegesService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: TOKEN_PROVIDER,
      useFactory: () => ({
        base: environment.production ? 'https://api.maimemo.com' : '',
        token: localStorage.getItem('idToken'),
      })
    }
  ]
})
export class AppModule {
}
